import React from 'react'
import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { Navbar, Container, Nav, Dropdown, Button } from 'react-bootstrap'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { _fetchApi } from 'redux/actions/api'
import moment from 'moment'
import { Alert, Col, Row } from 'reactstrap'
import { EmptyList } from 'views/app/Admin/ReportDetails'

function Notifications() {
  const { user, society } = useSelector((state) => state.auth)
  const [notificationList, setNotificationList] = useState([])

  const getNotifications = useCallback(() => {
    _fetchApi(`/notifications/${user.id}/${society.id}`, (d) => {
      if (d && d.data && d.data.length) {
        setNotificationList(d.data)
      }
    })
  }, [user.id, society.id])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  return (
    <Dropdown as={Nav.Item} drop="start">
      <Dropdown.Toggle
        as={Nav.Link}
        data-toggle="dropdown"
        id="dropdown-67443507"
        variant="default"
        className="m-0"
      >
        <IoMdNotificationsOutline size={30} />
        {/* <i className="nc-icon nc-planet"></i> */}
        <span className="notification">{notificationList.length}</span>
        <span className="d-lg-none ml-1">Notification</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
        style={{ height: "50vh", overflow: "scroll" }}
      >
        {notificationList.map((notif, index) => (
          <Dropdown.Item
            key={index}
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <div className="row">
              <p className="col-md-10">{notif.message}</p>
              <span className="col-md-2">
                {moment(notif.created_at).fromNow()}
              </span>
            </div>
            {/* <span className={notif.status === 1 ? 'font-weight-bold' : ''}>
               <span className='text-success'></span>
            </span> */}
          </Dropdown.Item>
        ))}
        {notificationList.length ? null : (
          <Alert color="secondary">
            You currently do not have any notification.
          </Alert>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Notifications
