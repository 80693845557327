import React from 'react'
import { Nav, Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { _fetchApi } from 'redux/actions/api'
import { getImageUrl } from 'redux/actions/api'
import { useHistory } from 'react-router'
import { getRoleLink } from 'views/helper'
import { FaUser } from 'react-icons/fa'
import { logout } from 'redux/actions/auth'

function UserAvatar() {
  const { user ,societies} = useSelector((state) => state.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <Dropdown as={Nav.Item} drop="start">
      <Dropdown.Toggle
        as={Nav.Link}
        data-toggle="dropdown"
        id="dropdown-67443507"
        variant="default"
        className="m-0"
      >
        {user.profile_pic && user.profile_pic !== '' ? (
          <img
            src={getImageUrl(user.profile_pic)}
            style={{ height: 30, width: 30 }}
            className='rounded'
          />
        ) : (
          <FaUser size={24} />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Item
          href="#pablo"
          onClick={(e) => {
            e.preventDefault()
            history.push(getRoleLink('/user-profile'))
          }}
        >
          View Profile
        </Dropdown.Item>
          {societies && societies.length>1?( <Dropdown.Item
          href="#pablo"
          onClick={(e) => {
            e.preventDefault()
            history.push('/auth/account-switch')
          }}
        >
         Switch Account
        </Dropdown.Item>):""}

       

        <Dropdown.Item
          href="#pablo"
          onClick={(e) => {
            e.preventDefault()
            dispatch(logout(history))
          }}
        >
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserAvatar
